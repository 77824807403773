<template>
  <c-flex flex-dir="column">
    <c-flex flex-dir="column" mt="15px" px="15px">
      <c-flex my="10px" align-items="center">
        <c-text flex="1" font-weight="bold" font-size="18px" color="#111111">
          Rencana makan
        </c-text>
        <c-button
          v-if="!isNutri"
          variant="outline"
          variant-color="brand"
          rounded="lg"
          as="router-link"
          :to="{ query: { intro: 1 } }"
        >
          <c-text>Info</c-text>
          <c-image ml="5px" :src="require('@/assets/ic-help-filled.svg')" alt="icon help" />
        </c-button>
        <c-button
          v-if="isNutri"
          variant-color="brand"
          border-radius="50px"
          as="router-link"
          :to="{ name: 'nutri.meal-plan.edit' }"
        >
          <c-image
            mr="10px"
            :src="require('@/assets/icon-pencil.svg')"
            alt="icon"
          />
          Edit
        </c-button>
      </c-flex>
      <c-flex p="8px" mt="8px" background="#F2F9F9" border-radius="8px">
        <c-image
          :src="require('@/assets/icon-info-circle-grey.svg')"
          alt="icon info"
          h="18px"
          w="18px"
        />
        <c-text ml="8px" font-size="12px" color="#555555">
          Daftar golongan makanan di bawah ini opsional.
        </c-text>
      </c-flex>
    </c-flex>

    <card-diet
      v-for="(menu, index) in menus_"
      :key="menu.id"
      :menu="menu"
      v-chakra
      :mt="[index == 0 ? '40px' : 0]"
    />
  </c-flex>
</template>

<script>
import CardDiet from "./cards/card-diet.vue";
import isNutriMixin from "@/utils/isNutri-mixins";

export default {
  components: { CardDiet },
  name: "MealPlanNonEmpty",
  props: ["menus"],
  mixins: [isNutriMixin],
  computed: {
    sorts() {
      return {
        breakfast: 1,
        morning_snack: 2,
        lunch: 3,
        afternoon_snack: 4,
        dinner: 5,
        night_snack: 6,
      }
    },
    menus_() {
      let menus = this.menus ?? [];

      if (!this.hasMenuFor("breakfast")) {
        menus.push(this.createBlankMenu("breakfast"));
      }
      if (!this.hasMenuFor("lunch")) {
        menus.push(this.createBlankMenu("lunch"));
      }
      if (!this.hasMenuFor("dinner")) {
        menus.push(this.createBlankMenu("dinner"));
      }
      if (!this.hasMenuFor("morning_snack")) {
        menus.push(this.createBlankMenu("morning_snack"));
      }
      if (!this.hasMenuFor("afternoon_snack")) {
        menus.push(this.createBlankMenu("afternoon_snack"));
      }
      if (!this.hasMenuFor("night_snack")) {
        menus.push(this.createBlankMenu("night_snack"));
      }

      menus.sort((a, b) => {
        return this.sorts[a.category] - this.sorts[b.category];
      });

      return menus;
    },
  },
  methods: {
    hasMenuFor(category) {
      return this.menus.find((it) => it.category === category);
    },
    createBlankMenu(category) {
      return {
        id: "category_" + category,
        timeStart: "00:00:00",
        timeEnd: "00:00:00",
        category: category,
        caloriesNeed: 0,
        totalCalories: 0,
        totalPortion: 0,
        createdAt: null,
        updatedAt: null,
        nutritionist: {},
        meals: [],
      };
    },
  },
};
</script>
